<template>
  <div class="app-container">
    <el-row :gutter="20">
      <div class="page-title" />
      <el-col :span="14">
        <Categorie />
      </el-col>
      <el-col :span="10">
        <Tags />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Categorie from './components/Categories';
import Tags from './components/Tags';

export default {
  name: 'CreateForm',
  components: { Categorie, Tags },
};
</script>
