<template>
  <div class="app-container">

    <el-card class="box-card">
      <div v-if="checkRole(['Admin'])" class="box-card filter-card just-for-admin">
        <div class="clearfix">
          <span>{{ $t('tag.tags') }}</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
        </div>
        <div class="filter-container">
          <el-radio v-model="activeList" label="actives" border @change="getSubTagsList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
          <el-radio v-model="activeList" label="desactives" border @change="getSubTagsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
          <el-radio v-model="activeList" label="disponibles" border @change="handleFilterTags">{{ $t('general.disponibles') }}</el-radio>
          <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubTagsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
        </div>
      </div>
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterTags" />
        <el-button v-if="checkPermission(['create tag'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateTag">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">

        <el-table-column align="center" :label="$t('tag.label')">
          <template slot-scope="scope">
            <span>{{ scope.row.label }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="activeList!='corbeille' && checkPermission(['activate tag'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setTagStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="activeList=='corbeille' && checkRole(['Admin'])" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setTagStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['edit tag','hide tag','delete tag'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="150">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['edit tag'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditTag(scope.row.id);" />
            <el-button v-if="checkPermission(['hide tag','delete tag'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteTag(scope.row.id, scope.row.is_visible, scope.row.label);" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getTagsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('tag.edit') + ' ' + currentTag.name : $t('tag.AddTag')"
        :visible.sync="dialogCreateTag"
        :before-close="handleCloseTagCreateDraw"
        :loading="tagCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="tagForm" :rules="rules" :model="currentTag" label-position="left">
            <el-form-item :label="$t('tag.label')" prop="label">
              <el-input v-model="currentTag.label" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentTag.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateTag=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="tagCreating" @click="editing ? updateTag() : createTag()">
              {{ tagCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const tagResource = new Resource('tags');
export default {
  name: 'TagsList',
  components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      tagCreating: false,
      editing: false,
      activeList: 'disponibles',
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      currentTag: {},
      dialogCreateTag: false,
      currentTagId: 0,
      rules: {
        label: [{ required: true, message: this.$t('tag.labelRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    this.resetNewTag();
    this.getTagsList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseTagCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelTagForm() {
      this.$refs.TagCreationDrawer.closeDrawer();
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
      };
      this.activeList = 'disponibles';
      this.handleFilterTags();
    },
    async getTagsList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await tagResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    async getSubTagsList(subUrl) {
      this.loading = true;
      const { data } = await tagResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterTags() {
      this.query.page = 1;
      this.getTagsList();
    },
    handleCreateTag() {
      this.editing = false;
      this.resetNewTag();
      this.dialogCreateTag = true;
      this.$nextTick(() => {
        this.$refs['tagForm'].clearValidate();
      });
    },
    handleEditTag(id){
      this.editing = true;
      this.resetNewTag();
      this.currentTag = this.list.find(abonne => abonne.id === id);
      this.dialogCreateTag = true;
      this.$nextTick(() => {
        this.$refs['tagForm'].clearValidate();
      });
    },
    async setTagStatus(id, type){
      const data = await tagResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteTag(id, visible, name) {
      this.$confirm(this.$t('tag.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['Admin']) || checkPermission(['delete tag']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              tagResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('tag.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setTagStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createTag() {
      this.$refs['tagForm'].validate((valid) => {
        if (valid) {
          this.currentTag.roles = [this.currentTag.role];
          this.tagCreating = true;
          tagResource
            .store(this.currentTag)
            .then(async(response) => {
              this.$message({
                message: this.$t('tag.NewTag') + ' ' + this.currentTag.label + ' ' + this.$t('tag.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentTagId = response.data.id;
              this.resetNewTag();
              this.dialogCreateTag = false;
              this.handleFilterTags();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.tagCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    updateTag() {
      this.$refs['tagForm'].validate((valid) => {
        if (valid) {
          this.tagCreating = true;
          tagResource.update(this.currentTag.id, this.currentTag).then(response => {
            this.resetNewTag();
            this.handleFilterTags();
            this.$message({
              message: this.$t('tag.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateTag = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.tagCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    resetNewTag() {
      this.currentTag = {
        label: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('tag.label'), this.$t('general.description')];
        const filterVal = ['id', 'label', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des régions GIFTOLIFE_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
